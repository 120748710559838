import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import dayjs from 'dayjs';
import ProLayout from '@ant-design/pro-layout';
import { createFromIconfontCN } from '@ant-design/icons';
import WaterMark from '@pansy/watermark';
import Cookies from 'js-cookie';
import { createInstance } from '@datapunt/matomo-tracker-react';
import { logout } from '@/global';
import { getUserInfo, getSiteInfo } from '@/utils';
import { userService, outboundCallService } from '@/service';

import TopRight from './topRight';
import SecondHeader from './second-header';
import CompanyDrawer from './companyDrawer';
import UserPay from './userPay';
import './style.module.less';
import ExpireModal from './expireModal';
import IpModal from './ipModal';

(() => {
  if (import.meta.env.VITE_APP_PRIVATE === 'tanke') {
    import('../../tanke.module.less');
  }
})();

const instance = createInstance({
  urlBase: 'https://mat.yunkecn.com',
  siteId: 1,
});

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1502858_a2vkj7wfaq9.js',
});
const IconFonts = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1781333_4s05zjng6g9.js',
});

const IconFontss = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2951654_95px88ui5wl.js',
});

const propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    listen: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.shape({}),
};
const defaultProps = {
  children: '',
};

let deploy = {};

const modules = import.meta.glob('../../ymls/*.yml', { eager: true });
Object.keys(modules).forEach((el) => {
  if (el.includes(import.meta.env.VITE_APP_PRIVATE)) {
    deploy = modules[el].default;
  }
});
deploy = deploy[import.meta.env.VITE_APP_PRIVATE].global;

class LayoutComponent extends Component {
  static unlisten = null

  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      topMenu: [],
      pathname: props.history.location.pathname,
      showCompanyDrawer: false,
      fromUrl: new URLSearchParams(props.location.search).get('from') || '',
      siteInfo: {},
      asrConfig: {
        status: false,
      },
      ipModalChange: false,
    };
  }

  async componentDidMount() {
    this.getCheckCache();
    this.watermark();
    // 检查浏览器版本
    let tooLow = false;
    // eslint-disable-next-line
    console.log(String.prototype.hasOwnProperty('matchAll'), 'isMatchAll');

    // eslint-disable-next-line
    if (String.prototype.hasOwnProperty('matchAll')) {
      const edgeVersion = [...navigator.userAgent.matchAll(/edge\/(\d+)\./gmi)];
      if (edgeVersion?.length > 0) {
        if (Number(edgeVersion[0][1]) < 37) {
          tooLow = true;
        }
      }
    } else if (this.getChromeVersion()) {
      // 针对chrome<73版本单独处理下
      const version = this.getChromeVersion();
      if (version < 65) {
        tooLow = true;
      }
    } else {
      tooLow = true;
    }
    if (tooLow) {
      message.warning('您的浏览器版本太低，使用网站时可能会有问题，建议尽快升级至最新版本的浏览器');
    }

    const {
      history,
    } = this.props;
    getSiteInfo();
    this.setState({
      siteInfo: localStorage.getItem('channelInfo') ? JSON.parse(localStorage.getItem('channelInfo')) : {},
    });
    const notNeedLogin = this.checkNotNeedLoginPage();
    if (history.location.pathname === '/auth/login') {
      const ischatworkbench = localStorage.getItem('ischatworkbench') || '';
      logout();
      localStorage.setItem('ischatworkbench', ischatworkbench);
      localStorage.setItem('menuInfo', '');
    }
    if (!notNeedLogin) {
      const user = Cookies.get('user');
      if (user) {
        localStorage.setItem('yunkeUser', `user=${user};cfa=${Cookies.get('cfa') || ''}`);
        localStorage.setItem('yunkeUrl', window.location.host);
      }
      const showFengkongMessage = localStorage.getItem('showFengkongMessage');
      if (showFengkongMessage !== dayjs().format('YYYY-MM-DD') && new Date().getTime() < new Date('2021-09-09').getTime()) {
        Modal.info({
          title: '到期提示',
          width: 600,
          content: (
            <p>微小助风控服务功能将于9月9日晚19时结束公测，如需继续使用，请联系您的商务人员。</p>
          ),
          onOk() {
            localStorage.setItem('showFengkongMessage', dayjs().format('YYYY-MM-DD'));
          },
        });
      }
      // 对于需要登录的页面，则必须检查用户信息
      await getUserInfo();

      this.setMenus();
      // 检查是否曾经运行过引导
      if (localStorage.getItem('doneTour') !== 'yeah!') {
        this.setState({
          // stepsEnabled: true,
        });
      }
    }
    const userInfo = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
    if (userInfo?.userId) {
      this.fetchData();
      instance.pushInstruction('setUserId', userInfo.userId);
    }
    this.unlisten = history.listen((l) => {
      const { location: newLocation } = this.props;
      if (newLocation.pathname !== l.pathname) {
        // 路由发生了变化
        this.addRecord();
      }
    });
    this.addRecord();
  }

  componentDidUpdate() {
    const {
      history, location,
    } = this.props;
    const { pathname } = this.state;

    if (pathname !== history.location.pathname) {
      // eslint-disable-next-line
      this.setState({
        pathname: history.location.pathname,
        fromUrl: new URLSearchParams(location.search).get('from') || '',
      }, () => this.setMenus());
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  getCheckCache = async () => {
    if (deploy?.checkCache) {
      const response = await userService.checkCache();
      if (response.success) {
        if (response.data) {
          window.location.href = '/cms/redirect';
        }
      } else {
        message.error(response.message);
      }
    }
  }

  getChromeVersion = () => {
    const arr = navigator.userAgent.split(' ');
    let chromeVersion = '';
    for (let i = 0; i < arr?.length; i += 1) {
      if (/chrome/i.test(arr[i])) chromeVersion = arr[i];
    }
    if (chromeVersion) {
      return Number(chromeVersion.split('/')[1].split('.')[0]);
    }
    return false;
  }

  fetchData = () => {
    if (!window.ipcRenderer || import.meta.env.VITE_APP_PRIVATE !== 'yunke') return;
    const userData = JSON.parse(localStorage.getItem('userData'));
    outboundCallService.querySipAccount().then((res) => {
      window.ipcRenderer?.send('emit', 'sip_account', {
        ...res?.data,
        userId: userData.userId,
        code: userData.companyId,
      });
    });
  }

  setMenus = async () => {
    const { history } = this.props;
    const origCurrentMenu = JSON.parse(localStorage.getItem('currentMenu'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const channelInfo = JSON.parse(localStorage.getItem('channelInfo'));
    let currentMenu = [];
    if (origCurrentMenu && Array.isArray(origCurrentMenu)) {
      currentMenu = origCurrentMenu;
    }
    await this.getCompanyAsrConfig();
    const {
      fromUrl,
      asrConfig,
    } = this.state;
    const icons = {
      100101: 'icon-shujutongji',
      100102: 'icon-shoujitongji',
      100103: 'icon-dakatongji',
      100501: 'icon-weixinkanban',
      100502: 'icon-weixinhaoguanli1',
      100105: 'icon-mingancitongji',
      100104: 'icon-shujubaobiao',
      100503: 'icon-weixinfengkong',
      10050303: 'iconweixinfengkong',
      100301: 'iconweixinfengkong',
      100504: 'iconfuzhuyingxiao',
      100506: 'icon-kefuxitong1',
      100507: 'iconweixiaoquan',
      100509: 'icon-douyin',
      100508: 'iconyunmingpian',
      100505: 'icon-weixinshezhi1',
      101601: 'iconweixinhaoguanli',
      101602: 'iconweixinfengkong',
      101401: 'icon-weixinkehuliebiao',
      101403: 'icon17fuzhuyingxiao',
      100303: 'icon17fuzhuyingxiao',
      100302: 'icon18qiyeweixinyingxiao',
      101405: 'icon-weixiaozhushezhi',
      101603: 'iconshezhi',
      101604: 'iconweixinkanban',
      101703: 'iconshezhi',
      101704: 'iconweixinkanban',
      101701: 'iconweixinhaoguanli',
      100201: 'icon12kehuliebiao',
      100202: 'icon13tonghualishi',
      100203: 'icon14duanxinlishi',
      100204: 'iconweixinfengkong',
      100205: 'icon15qiyegonghai',
      100206: 'icon16xiaoshouluyin',
      100207: 'icon13tonghualishi',
      100801: 'icon12kehuliebiao',
      100802: 'icon13tonghualishi',
      101406: 'iconyingxiaogongju-shoujihaibao',
      102001: 'iconshezhi',
      102002: 'icon12kehuliebiao',
      102003: 'icon20yingxiaorenwu',
      102004: 'icon18qiyeweixinyingxiao',
      102005: 'iconweixinhaoguanli',
      102006: 'iconweixinfengkong',
      104202: 'icon-huiyuanguanli',
      104201: 'icon-jifenguanli',
      userDetail: 'icon-gerenxinxi2',
      import: 'icon-daoru',
      myaccount: 'icon-gerenxinxi1',
      dowload: 'icon-tianchongxing-',
      100209: 'icon-zhuangshishuazi-2',
      100210: 'icon-yunwei-xitongjiance',
      100221: 'icon-kehuguanli',
      100222: 'icon-goutongjilu',
      100220: 'icon-waihuxitong',
      100223: 'icon-gongju',
      100510: 'icon-QQ1',
      100511: 'icon-whatsappguanli',
      1016: 'icon-qiweiguanfangguanli',
      100513: 'icon-qiyeweixinguanli',
      100514: 'icon-weishujuliucun1',
      105006: 'icon-shuju',
      105002: 'icon-weixin',
      105003: 'icon-qiwei',
      105007: 'icon-guankong',
      105005: 'icon-peizhi',
      105009: 'icon-duihua',
      100751: 'icon-a-yonghuquanxianshezhi',
      100752: 'icon-zidingyiyewubiaoqian',
      100753: 'icon-yewushuxingshezhi',
      100754: 'icon-xitongshezhi',
      102201: 'icon-shebeitongji',
      102202: 'icon-shebeiguanli',
      102203: 'icon-dingweiguankong',
      102204: 'icon-yingyongguankong',
      102205: 'icon-waihuguankong',
      102207: 'icon-shoujifengkong',
      102206: 'icon-shebeizhuangtaiguanli',
      102209: 'icon-guankongzhushouAPPduan',
      101407: 'icon-shujukanban',
      101402: 'icon-weixinyingxiaorenwu',
      101404: 'icon-qiyeweixinrenwu',
      101412: 'icon-liaotianjilu',
      101413: 'icon-kehubiaoqian',
      101409: 'icon-douyinyingxiao',
      105301: 'icon-weixin',
      105302: 'icon-peizhi',
      105201: 'icon-yunxiaozhi',
      105202: 'icon-jichushezhi',
      105203: 'icon-gongju1',
      103605: 'icon-fuzhuyingxiao',
      103602: 'icon-shouyinzhushou',
      103603: 'icon-haibaoguangchang',
      103604: 'icon-wailiangongju',
      103607: 'icon-tuoke',
      103609: 'icon-tuoke',
      103611: 'icon-liaotiangongju',
      103612: 'icon-neibudingdan',
      103613: 'icon-liaotiangongju',
      105101: 'icon-jichushezhi1',
      105102: 'icon-rexianfuwu',
      105103: 'icon-rexianguanli',
      105104: 'icon-jiankongyutongji',
      105105: 'icon-zhishiku',
      103406: 'icon-zhibo',
      103408: 'icon-yingxiaohuodong',
      103401: 'icon-shuju1',
      104203: 'icon-xitongshezhi1',
      103501: 'icon-shuju4',
      103502: 'icon-qudao',
      103503: 'icon-yongjinshezhi',
      103504: 'icon-yingxiaojiangli',
      103505: 'icon-shezhi',
      103506: 'icon-tuikeguanli',
      103402: 'icon-shangcheng',
      103403: 'icon-dingdan',
      103404: 'icon-xuexi',
      103405: 'icon-sucai',
    };

    const windowPath = window.location.pathname + window.location.hash;
    let menu = currentMenu.find((item) => {
      const first = item.children?.find((second) => {
        if (second.url?.startsWith('/pc/cms')) {
          // TODO 这里后端接口有错误
          // eslint-disable-next-line
          second.url = second.url.substring(3);
        }
        if (second.children) {
          return second.children.find(
            (third) => windowPath.includes(third.url) || fromUrl?.includes(third.url),
          );
        }
        return windowPath.includes(second.url) || fromUrl?.includes(second.url);
      });
      if (first) {
        return first;
      }
      if (windowPath.includes(item.url)) {
        return item;
      }
      return null;
    });
    const navigation = ['/dowload',
      '/convertText',
      '/taskCenter',
      '/import',
      '/expenditureDetails',
      '/home/myaccount', '/home/applyOutbound',
      '/home/outboundList', '/home/electronicSales', '/home/OpenTransferOutbound', '/home/transferOutbound', '/home/userDetail', '/home/electronList', '/home/intelligentAssistant', '/home/sipIntroduce', '/home/studioCamera'];
    if (menu?.id === '1007' && menu?.children?.length > 10) {
      // 初始的时候设置菜单需要改造一下，改造完成之后就不用再次改造了
      const newMenu = [
        {
          name: '常用设置',
          pId: '1007',
          id: 102001,
          url: '/cms/settings/index',
        },
        {
          name: '用户&权限设置',
          pId: '1007',
          id: 102002,
          children: [],
        },
        {
          name: '自定义业务标签',
          pId: '1007',
          id: 102003,
          children: [],
        },
        {
          name: '业务属性设置',
          pId: '1007',
          id: 102004,
          children: [],
        },
        {
          name: '手机管理',
          pId: '1007',
          id: 102005,
          children: [],
        },
        {
          name: '系统设置',
          pId: '1007',
          id: 102006,
          children: [],
        },
      ];
      menu.children.forEach((item) => {
        newMenu[item.groupSort]?.children?.push(item);
      });
      menu.children = newMenu;
    } else if (navigation.includes(history.location.pathname)
     || history.location.pathname.startsWith('/home/pc')
     || history.location.pathname.startsWith('/home/transferOutbound')
     || history.location.pathname.startsWith('/home/outboundList')
    ) {
      const childrenlist = [
        {
          name: '我的产品',
          id: 'myaccount',
          url: '/cms/expenditureDetails',
          show: true,
        },
        {
          name: '语音转文字',
          id: 'convertText',
          url: '/cms/convertText',
          show: asrConfig.status,
        },
        {
          name: '企业微信',
          id: 'qiyeweixin',
          url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/qiyeweixin.html')}`,
          show: true,
        },
        {
          name: '微小助',
          id: 'weixiaozhu',
          url: `/cms/home/pc/${encodeURIComponent('https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/html/weixiaozhu.html')}`,
          show: true,
        },
      ];
      const newMenu = {
        children: [
          {
            name: '个人信息',
            id: 'userDetail',
            url: '/cms/home/userDetail',
            show: true,
          },
          {
            name: '企业账户',
            id: 'myaccount',
            url: '/cms/home/myaccount',
            show: deploy?.my_account && (userInfo.userType === 1 || userInfo.userType === 2)
            && channelInfo?.allowCharge === 1,
          },
          {
            name: '我的产品',
            id: 'expenditureDetails',
            url: '/cms/expenditureDetails',
            show: deploy?.my_account && (userInfo.userType === 1 || userInfo.userType === 2)
            && channelInfo?.allowCharge === 1,
            children: childrenlist.filter((item) => item.show),
          },
          {
            name: '下载中心',
            id: 'dowload',
            url: '/cms/dowload',
            show: true,
          },
          {
            name: '导入记录',
            id: 'import',
            url: '/cms/import',
            show: deploy?.import_list,
          },
          {
            name: '任务中心',
            id: 'taskCenter',
            url: '/cms/taskCenter',
            show: deploy?.task_list,
          },
        ],
      };
      menu = {
        children: newMenu?.children?.filter((item) => item.show),
      };
    }
    this.setState({
      topMenu: currentMenu?.filter((_) => _.pId === '0')?.filter((item) => item.children)?.map((item) => ({ // filter  处理接口数据二级导航 放到一级导航上
        path: item.id === menu?.id ? windowPath : item.url,
        name: item.id === menu?.id ? item.name : (
        // 注意：这里不能用Link，因为我们的顶级菜单是用乾坤子应用跳转
        // 所以，这里只能用a标签
          <a href={item.url}>{item.name}</a>
        ),
      })),
      routes: menu?.children?.map((item) => {
        let firstPath = '';
        if (!item.children) {
          if (item.url.startsWith('/cms')) {
            firstPath = item.url;
          } else {
            // eslint-disable-next-line
            firstPath = '/cms/settings' + item.url
          }
        }
        let nameLink = '';
        if (!item.children) {
          if (item.url.startsWith('/cms')) {
            nameLink = item.url.substring(4);
          } else {
            // eslint-disable-next-line
            nameLink = '/settings' + item.url
          }
        }
        return {
          path: firstPath,
          key: firstPath.includes('#') ? new Date().getTime().toString() : firstPath,
          name: item.children ? item.name : (
            <>
              {item.url === '/cms/chatworkbench/index' || item.url === 'http://qia.novoai.com.cn' ? (
                <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>) : (
                  <a href={item.url}>{item.name}</a>
              )}
            </>
          ),
          icon: icons[item.id] ? <IconFonts type={icons[item.id]} /> || <IconFontss style={{ fontSize: 16 }} type={icons[item.id]} /> : <IconFont type="iconshezhi" />,
          routes: item.children?.map((second) => {
            // 暂时用a跳转
            const name = (
              <a
                href={second.url}
              >
                {second.name}
              </a>
            );
            return ({
              path: second.url.replace(/#/, '1'),
              key: second.url.includes('#') ? new Date().getTime().toString() : second.url,
              name,
            });
          }),
        };
      }),
    });
  }

  /**
   * 记录页面访问数据
   */
  addRecord = () => {
    if (import.meta.env.VITE_APP_PRIVATE === 'yunke' && window.location.hostname === 'crm.yunkecn.com') {
    // 只有公版正式才统计
      const userInfo = localStorage.getItem('userData') && localStorage.getItem('userData') !== 'undefined' ? JSON.parse(localStorage.getItem('userData')) : {};
      const href = window.location.origin + window.location.pathname;
      instance.trackPageView({
        href: window.location.pathname.includes('/cms/wechat/salewechat/message/wechat-window') ? '/cms/wechat/salewechat/message/wechat-window' : href,
        customDimensions: [
          {
            id: 1,
            value: userInfo?.companyId,
          },
          {
            id: 2,
            value: userInfo?.userId,
          },
          {
            id: 3,
            value: userInfo?.cellphone,
          },
          {
            id: 4,
            value: userInfo?.realName,
          },
        ],
      });
    }
  }

  getCompanyAsrConfig = async () => {
    if (import.meta.env.VITE_APP_PRIVATE === 'yunke') {
      const { history } = this.props;
      const navigation = [
        '/dowload',
        '/convertText',
        '/taskCenter',
        '/import',
        '/home/myaccount',
      ];
      if (navigation.includes(history.location.pathname)) {
        const response = await userService.getAsrCompanyStatus();
        if (response.success) {
          this.setState({
            asrConfig: response.data,
          });
        }
      }
    }
  }

  /**
   * 显示水印
   * @returns
   */
  watermark = async () => {
    const { history } = this.props;
    const userInfo = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
    // 登录及注册页面不获取水印配置
    const noNeedWatermarkPages = ['/auth/login', '/auth/reg', '/auth/forgotpass', '/auth/accountcheck', '/auth/changephone', '/auth/announce'];
    if (noNeedWatermarkPages.includes(history.location.pathname)) return;
    if (history.location.pathname.includes('/yunxiaozhi/chat')) return;
    const response = await userService.getWatermark().catch((error) => {
      if (error.code === 302) {
        logout('/cms/auth/login');
      }
    });
    if (response?.success) {
      if (response.data.show) {
        const waterMark = new WaterMark();
        waterMark.update({
          text: userInfo.realName + userInfo.cellphone.slice(-4),
          rotate: -20,
        });
        waterMark.render();
      }
    }
  }

  /**
   * 这些页面不需要登录
   * @returns
   */
  checkNotNeedLoginPage = () => {
    const { pathname } = window.location;
    return /\/login|reg|authorize|forgetpass|announce|forgotpass|accountcheck|changephone|resetpwd/.test(
      pathname,
    );
  }

  /**
   * 这些页面不需要顶部菜单栏
   * @returns
   */
  checkNotNeedLayoutPage = () => {
    const { pathname } = window.location;
    return /auth\/login|redirect|\/reg|authorize|resetpwd|\/404|\/500|forgetpass|announce|forgotpass|callVoice|salewechat(.*)|callDetail(.*)|saleqq(.*)|wschatroom(.*)|\/detail\/message|\/detail\/addgroup|\/detail\/exitgroup|accountcheck|changephone|weChatManage|wechatSensitive|collectCustomers|topuprecord|numberCleaningDetail|detailedList|numberCancellation|continuityOutboundList(.*)|dataPanel|callQualityInspection/.test(
      pathname,
    );
  }

  /**
   * 这些页面不需要左侧菜单栏
   * @returns
   */
  checkNotSidebarPage = () => {
    const { pathname } = window.location;
    if (sessionStorage.getItem('yxz_role') === 'admin' && /yunxiaozhi\/chat/.test(pathname)) {
      return false;
    }
    return /remote|qualityTesting|scrm|cofco|mochat|mcrm|dataPanel|yunxiaozhi\/chat/.test(pathname);
  }

  render() {
    const {
      children, history,
    } = this.props;
    const userInfo = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
    const companyInfo = localStorage.getItem('companyInfo') ? JSON.parse(localStorage.getItem('companyInfo')) : {};
    const menuInfo = localStorage.getItem('menuInfo') ? JSON.parse(localStorage.getItem('menuInfo')) : [];
    const nowData = new Date().getTime();
    const openIpDate = companyInfo?.openIpDate
      ? new Date(companyInfo?.openIpDate).getTime()
      : 0;
    const {
      routes, topMenu, showCompanyDrawer, siteInfo, ipModalChange, /* stepsEnabled */
    } = this.state;

    const notNeedLayout = this.checkNotNeedLayoutPage();
    if (notNeedLayout) {
      return (
        <div className="h-full overflow-auto">
          <div id="subapp-viewport" className="h-full">
            {children}
          </div>
        </div>
      );
    }
    const isSecondHeader = ['/cms/home/statistics'].includes(window.location.pathname);
    return (
      <>
        <ProLayout
          layout="top"
          // navTheme="light"
          disableContentMargin
          logo={siteInfo?.navigationLogo ? siteInfo?.navigationLogo : false}
          title={false}
          onMenuHeaderClick={() => { window.location.href = '/cms/'; }}
          route={{
            routes: topMenu,
          }}
          menuProps={{ style: { textAlign: 'center', justifyContent: 'center' } }}
          contentStyle={{ display: 'flex', flexDirection: 'column' }}
          rightContentRender={() => (userInfo
            ? (
              <TopRight
                history={history}
                permissions={menuInfo}
              />
            ) : false)}
        >
          <div className="flex flex-col flex-1 overflow-hidden" style={{ height: 1 }}>
            {/* 只有首页显示 */}
            {isSecondHeader
                && (
                <SecondHeader
                  companyInfo={companyInfo}
                  changeCompanyDrawer={() => this.setState({ showCompanyDrawer: true })}
                />
                )}
            <div className="flex flex-col flex-1 overflow-hidden">
              {routes?.length !== 0 ? (
                <ProLayout
                  title="首页"
                  navTheme="light"
                  menuHeaderRender={false}
                  menuRender={(props, defaultDom) => (
                    (this.checkNotSidebarPage() || !routes) ? false : defaultDom)}
                  route={{
                    routes,
                  }}
                  contentStyle={{ height: 1 }}
                  headerRender={false}
                  disableContentMargin
                  menu={{ defaultOpenAll: true, autoClose: false }}
                >
                  <div id="subapp-viewport" className="h-full flex-1 flex flex-col subapp">
                    {children}
                  </div>
                </ProLayout>
              ) : (
                <div id="subapp-viewport" className="h-full flex-1 flex flex-col subapp">
                  {children}
                </div>
              )}
            </div>
            <CompanyDrawer
              visible={showCompanyDrawer}
              onClose={() => this.setState({ showCompanyDrawer: false })}
            />
            <UserPay />
          </div>
        </ProLayout>
        <ExpireModal openIp={() => {
          this.setState({ ipModalChange: true });
        }}
        />
        {nowData < openIpDate && ipModalChange && localStorage.getItem('ipModal') === '1' && (
          <IpModal
            onClose={() => {
              localStorage.removeItem('ipModal');
              this.setState({ ipModalChange: false });
              localStorage.setItem('expire', '1');
            }}
          />
        )}

      </>
    );
  }
}

LayoutComponent.propTypes = propTypes;
LayoutComponent.defaultProps = defaultProps;
export default withRouter(LayoutComponent);
