// 设备模块路由，首页新页面路由别在这里添加
import { lazy } from 'react';

const DeviceStatic = lazy(() => import('@/pages/device/deviceStatic'));
const Phonescreenshot = lazy(() => import('@/pages/home/phoneClient'));
const MobileCard = lazy(() => import('@/pages/home/mobilecard'));
const MobileLocation = lazy(() => import('@/pages/home/mobilelocation'));
const GeofencingLog = lazy(() => import('@/pages/geofencing/log/index'));
const GeofencingSetup = lazy(() => import('@/pages/geofencing/setup/index'));
const Geofencing = lazy(() => import('@/pages/geofencing'));
const DeviceOffLineStatic = lazy(() => import('@/pages/device/deviceStatic/offLine'));
const DetailedList = lazy(() => import('@/pages/device/deviceStatic/detailedList'));

export default [
  /**
   * 设备/手机统计
   */
  {
    name: '微信应用安装明细',
    path: '/device/devicestatistics/detailedList',
    key: 'detailedList',
    component: DetailedList,
  },
  {
    name: '设备统计',
    path: '/device/devicestatistics',
    key: 'devicestatistics',
    component: DeviceStatic,
  },
  {
    name: '设备离线统计',
    path: '/device/deviceOffLineStatic',
    key: 'deviceOffLineStatic',
    component: DeviceOffLineStatic,
  },
  {
    name: '手机截屏',
    path: '/device/phonescreenshot',
    key: '/device/phonescreenshot',
    component: Phonescreenshot,
  },
  {
    name: '插拔卡统计',
    path: '/device/mobileCard',
    key: 'mobileCard',
    component: MobileCard,
  },
  /**
   * 设备/手机定位
   */
  {
    name: '手机定位',
    path: '/device/mobilelocation',
    key: '/device/mobilelocation',
    component: MobileLocation,
  },
  {
    name: '围栏设置',
    path: '/device/geofencing',
    key: '/device/geofencing',
    component: Geofencing,
  },
  {
    name: '围栏日志',
    path: '/device/geofencinglog',
    key: '/device/geofencinglog',
    component: GeofencingLog,
  },
  {
    name: '围栏通知设置',
    path: '/device/geofencingsetup',
    key: '/device/geofencingsetup',
    component: GeofencingSetup,
  },
];
